/* eslint-disable react/no-danger */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FOOTER_LEGAL_LINKS } from '../../config/links/links';
import { SITE_LIVINGSOCIAL_IE } from '../../config/setup/setup';
import {
  ALL_RIGHTS_RESERVED,
  AVAILABLE_PAYMENT_METHODS,
  COMPANY_LEGAL_NAME,
  COMPANY_TRADING_NAME,
  PAYPAL_CREDIT_LEGAL,
  PAYPAL_CREDIT_LEGAL_CHECKOUT_FOOTER,
  PRICES_SUBJECT_TO_CHANGE,
  SUBJECT_TO_AVAILABILITY,
} from '../../config/text/text';
import { isMobileViewport } from '../../helpers/screen';
import ThemeContext from '../../providers/ThemeProvider';

const FooterLegalText = ({
  isCheckout,
  isSecurePage,
  isVouchers = false,
  site,
}) => {
  const theme = useContext(ThemeContext);
  const year = new Date().getFullYear();
  const companyLegalName = COMPANY_LEGAL_NAME[site];
  const companyLegalLinks = FOOTER_LEGAL_LINKS[site];
  const companyTradingName = COMPANY_TRADING_NAME[site];
  const paypalCreditLegal = isCheckout
    ? PAYPAL_CREDIT_LEGAL_CHECKOUT_FOOTER.replace(
        /##COMPANY_TRADING_NAME##/g,
        companyTradingName,
      )
    : PAYPAL_CREDIT_LEGAL.replace(
        /##COMPANY_TRADING_NAME##/g,
        companyTradingName,
      );
  const payPalCreditEnabled = JSON.parse(
    process.env.NEXT_PUBLIC_PAYPAL_CREDIT_ENABLED === 'true',
  );
  const isLSIE = site === SITE_LIVINGSOCIAL_IE;
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <>
      {isClient ? (
        <div
          className={classNames('legal-text__container', {
            'legal-text__container--secure': isSecurePage,
          })}
        >
          <p className="legal-text">{AVAILABLE_PAYMENT_METHODS}</p>

          {!isLSIE && payPalCreditEnabled && (
            <div
              className="legal-text"
              dangerouslySetInnerHTML={{ __html: paypalCreditLegal }}
              data-testid="paypal"
            />
          )}
          <p className="legal-text">
            &copy; {year} {companyLegalName}. {ALL_RIGHTS_RESERVED}.{' '}
            {companyLegalLinks.map((link, index) => {
              if (!link) return null;

              return (
                <a
                  aria-label={link?.text}
                  className="legal-text__link"
                  href={link.href}
                  key={index}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  {link?.text}
                </a>
              );
            })}
          </p>
          {isCheckout && (
            <p className="legal-text" data-testid="prices">
              {`${PRICES_SUBJECT_TO_CHANGE}${
                isVouchers ? `.` : `, ${SUBJECT_TO_AVAILABILITY}.`
              }`}
            </p>
          )}
        </div>
      ) : null}
      <style jsx>{`
        .legal-text {
          font-size: ${isMobileViewport() ? '10px !important' : '12px'};
          margin-bottom: 5px;
          color: ${theme.colors.footerlegaltext};
        }
        .legal-text__container--secure .legal-text {
          font-size: inherit;
        }
        .legal-text:last-child {
          margin-bottom: 5px;
        }
        .legal-text__link {
          display: inline-block;
          font-weight: bold;
          text-decoration: none;
          color: ${theme.colors.footerlegaltext};
        }
        .legal-text__link:hover {
          text-decoration: underline;
          color: ${theme.colors.primary};
        }
        .legal-text__link:after {
          content: ', ';
          display: inline-block;
          white-space: pre;
          color: ${theme.colors.footerlegaltext};
          text-decoration: none;
        }
        .legal-text__link:last-child:after {
          content: '.';
        }
        @media (max-width: ${theme.breakpoints.mdDown}) {
          .legal-text {
            font-size: inherit;
          }
          .legal-text__link {
            display: none;
          }
          .legal-text__container--secure .legal-text__link {
            display: inline-block;
          }
        }
      `}</style>
    </>
  );
};

FooterLegalText.propTypes = {
  isCheckout: PropTypes.bool,
  isSecurePage: PropTypes.bool,
  isVouchers: PropTypes.bool,
  site: PropTypes.string.isRequired,
};

export default FooterLegalText;
